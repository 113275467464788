import { useGetTeamGoalsStats } from '@src/api/teams'
import { useGetDepartmentGoalsStats } from '@src/api/department'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'

const goalsOwnerToFetcherMap: Record<
  GoalsOwner,
  typeof useGetTeamGoalsStats | typeof useGetDepartmentGoalsStats
> = {
  [GoalsOwner.Team]: useGetTeamGoalsStats,
  [GoalsOwner.Department]: useGetDepartmentGoalsStats,
}

interface GoalsStats {
  totalCount: number
  withGoalsCount: number
  isLoading: boolean
  isError: boolean
}

export const useGoalsStats = (
  cycle: ReviewCyclesInterface,
  goalsOwner: GoalsOwner,
): GoalsStats => {
  const useFetcher = goalsOwnerToFetcherMap[goalsOwner]

  const totalFetcher = useFetcher(cycle.id)
  const withGoalsFetcher = useFetcher(cycle.id, true)

  return {
    totalCount: totalFetcher.data?.count ?? 0,
    withGoalsCount: withGoalsFetcher.data?.count ?? 0,
    isLoading: totalFetcher.isLoading || withGoalsFetcher.isLoading,
    isError: totalFetcher.isError || withGoalsFetcher.isError,
  }
}
