import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/CommonActions'
import { MoreBar, Text } from '@revolut/ui-kit'
import { reviewCyclesRequestsNew, useStartReviewCycle } from '@src/api/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props {
  cycle: ReviewCyclesInterface
}

export const PlannedCycleActions = ({ cycle }: Props) => {
  const { id, name } = cycle

  const { mutateAsync: startCycle, isLoading } = useStartReviewCycle(id)
  const permissions = useSelector(selectPermissions)
  const showStatusPopup = useShowStatusPopup()

  const handleStartCycle = async () => {
    await startCycle()
    showStatusPopup({ title: `${name} cycle is successfully started` })
  }

  const canDelete =
    permissions.includes(PermissionTypes.DeleteReviewCycles) && cycle.delete_allowed

  const handleDeleteCycle = async () => {
    if (!canDelete) {
      return
    }

    await reviewCyclesRequestsNew.delete({ id }, {})
  }

  return (
    <MoreBar maxCount={3} aria-label="action panel">
      <MoreBar.Action useIcon="Play" onClick={handleStartCycle} pending={isLoading}>
        Start Cycle
      </MoreBar.Action>
      <CommonActions cycle={cycle} />
      {canDelete && (
        <NewSaveButtonWithPopup<ReviewCyclesInterface>
          confirmationDialogue={{
            body: (
              <Text>
                This will also delete all related objects such as scorecards and grades.{' '}
                <b>This action is not reversible.</b>
              </Text>
            ),
          }}
          variant="negative"
          successText="Review cycle deleted succesfully"
          useIcon="Delete"
          isMoreAction
          onAfterSubmit={() => navigateTo(ROUTES.PERFORMANCE.REVIEWS_CYCLES)}
          onClick={handleDeleteCycle}
        >
          Delete cycle
        </NewSaveButtonWithPopup>
      )}
    </MoreBar>
  )
}
