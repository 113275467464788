import { CSSProperties } from 'react'
import { Token } from '@revolut/ui-kit'

// TODO: REVCOR-3046 revisit after ui-kit update
export const transparentThemeBackgroundOverrides: CSSProperties = {
  backgroundColor: 'transparent',
}

export const tooltipContentOverrides: CSSProperties = {
  boxShadow: '0px 3px 8px 0px rgba(25, 28, 31, 0.45)',
  borderRadius: Token.radius.r8,
}
