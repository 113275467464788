import React, { useEffect, useState } from 'react'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { pathToUrl } from '@src/utils/router'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Hierarchy from './Hierarchy'
import TalentTab from '@src/pages/Forms/CommonTalentTab/Talent'
import { TalentType } from '@src/interfaces/talent/talent'
import { getEmployeeWithManagerFilter } from '@src/api/employees'
import { Hiring } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/Hiring'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

export const Talent = ({ stats, data }: Props) => {
  const params = useParams<{ id: string }>()
  const { data: performanceSettings } = useGetPerformanceSettings()

  const [functionalManagerPermissions, setFunctionalManagerPermissions] =
    useState<string[]>()

  useEffect(() => {
    getEmployeeWithManagerFilter(data.id, 'quality_control__id').then(res =>
      setFunctionalManagerPermissions(res.data.field_options.permissions),
    )
  }, [])

  const isNewLayout = useIsNewLayout()

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
        params,
      ),
      title: 'Direct reports',
      component: TalentTab,
      talentType: TalentType.DirectReport,
      canView: true,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS,
        params,
      ),
      title: 'Functional reports',
      component: TalentTab,
      talentType: TalentType.FunctionalReport,
      canView: performanceSettings?.enable_functional_management,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY,
        params,
      ),
      title: 'Hierarchy',
      component: Hierarchy,
      canView: !isNewLayout,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIRING,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIRING, params),
      title: 'Hiring',
      component: Hiring,
      canView: isNewLayout,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)
  const navigation = (
    <Flex alignItems="flex-start" mb="s-24">
      <TabBarNavigation
        isSubtab
        tabs={filteredTabs}
        key={stats?.kpi_performance_percent}
      />
    </Flex>
  )

  return (
    <>
      {!isNewLayout && navigation}
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component
              data={
                tab.talentType === TalentType.FunctionalReport
                  ? {
                      ...data,
                      field_options: {
                        ...data.field_options,
                        permissions: functionalManagerPermissions,
                      },
                    }
                  : data
              }
              talentType={tab.talentType!}
              navigation={isNewLayout ? navigation : undefined}
            />
          </Route>
        ))}
        <InternalRedirect to={filteredTabs[0].path} />
      </Switch>
    </>
  )
}
