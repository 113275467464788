import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { CustomFieldsInterface } from '@src/interfaces/customFields'

export const customFieldNameColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.custom_field_names,
  title: 'Field name',
}

export const customFieldCreatedByColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'created_by.id',
  dataPoint: 'created_by.full_name',
  sortKey: 'created_by__full_name',
  filterKey: 'created_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Created By',
}

export const customFieldGroups: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.insert,
  idPoint: 'dynamic_groups',
  dataPoint: 'dynamic_groups',
  sortKey: null,
  filterKey: 'dynamic_groups',
  selectorsKey: selectorKeys.dynamic_groups,
  title: 'Groups',
  insert: ({ data }) => data.dynamic_groups.map(group => group.name).join(', '),
}

export const customFieldSectionColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'section.id',
  dataPoint: 'section.name',
  sortKey: 'section__name',
  filterKey: 'section__id',
  selectorsKey: selectorKeys.custom_field_sections,
  title: 'Section',
}

export const customFieldSubSectionColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'sub_section.id',
  dataPoint: 'sub_section.name',
  sortKey: 'sub_section__name',
  filterKey: 'sub_section__id',
  selectorsKey: selectorKeys.employee_profile_sub_sections,
  title: 'Subsection',
}

export const customFieldInputTypeColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'input_type',
  dataPoint: 'input_type.name',
  sortKey: 'input_type',
  filterKey: 'input_type',
  selectorsKey: selectorKeys.custom_field_input_types,
  title: 'Input type',
}

export const customFieldMandatoryColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.insert,
  idPoint: 'mandatory',
  dataPoint: 'mandatory',
  sortKey: 'mandatory',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (data.mandatory ? 'True' : 'False'),
  title: 'Mandatory',
}

export const customFieldPlaceholderColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'placeholder',
  dataPoint: 'placeholder',
  sortKey: 'placeholder',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Placeholder',
}

export const customFieldTooltipColumn: ColumnInterface<CustomFieldsInterface> = {
  type: CellTypes.text,
  idPoint: 'tooltip',
  dataPoint: 'tooltip',
  sortKey: 'tooltip',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Tooltip',
}

export const customFieldCreatedDateColumn: ColumnInterface<CustomFieldsInterface> = {
  title: 'Created on',
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
}

export const customFieldStatusColumn: ColumnInterface<CustomFieldsInterface> = {
  title: 'Status',
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.custom_field_statuses,
}
