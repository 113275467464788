import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import {
  ExpandableCell,
  Token,
  Text,
  Details,
  VStack,
  HStack,
  Icon,
} from '@revolut/ui-kit'

import {
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PAYROLL_ISSUES_LEVEL_WARNING,
  PayrollTimelineChangeInterface,
} from '@src/interfaces/payrollV2'
import { ParsedDomainFieldChanges } from '..'
import { ValuesDiff } from '../ValuesDiff'

type Props = {
  data: {
    change: PayrollTimelineChangeInterface
    fieldsChanges: ParsedDomainFieldChanges[]
  }
  title: string
  subtitle?: string
  isOpenByDefault?: boolean
}
export const DomainChangesWidget = ({
  data,
  title,
  subtitle,
  isOpenByDefault = true,
}: Props) => {
  const [expanded, setExpanded] = useState(isOpenByDefault)

  const { change, fieldsChanges } = data
  const hasIssues = isPlainObject(change.issues) && !isEmpty(change.issues)
  const issuesLevelColor =
    change.issues_level.id === PAYROLL_ISSUES_LEVEL_CRITICAL
      ? Token.color.danger
      : change.issues_level.id === PAYROLL_ISSUES_LEVEL_WARNING
      ? Token.color.warning
      : undefined

  if (!fieldsChanges.length) {
    return null
  }
  return (
    <ExpandableCell expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <ExpandableCell.Title>{title}</ExpandableCell.Title>
      {!!subtitle && (
        <ExpandableCell.Content>
          <Text color={Token.color.greyTone50}>{subtitle}</Text>
        </ExpandableCell.Content>
      )}
      <ExpandableCell.Note>
        {fieldsChanges.map(parsedField => {
          const { from, to, label, changeType } = parsedField
          return (
            <Details key={label}>
              <Details.Title>{label}</Details.Title>
              <Details.Content>
                <ValuesDiff from={from} to={to} type={changeType} />
              </Details.Content>
            </Details>
          )
        })}
        {hasIssues && (
          <VStack space="s-8" py="s-8">
            {Object.values(change.issues).map(issue => (
              <HStack key="issue" space="s-8" align="center">
                <Icon name="ExclamationMarkOutline" color={issuesLevelColor} size={15} />
                <Text color={issuesLevelColor}>{issue}</Text>
              </HStack>
            ))}
          </VStack>
        )}
      </ExpandableCell.Note>
    </ExpandableCell>
  )
}
