import React from 'react'
import type { ReactNode } from 'react'
import type { TooltipProps } from 'recharts'
import { LightBackgroundTooltip } from './LightBackgroundTooltip'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'

type ChartTooltipProps<T> = TooltipProps<number, string> & {
  tooltip?: (props: T, dataKeys?: DataKey<any>[], colors?: string[]) => ReactNode
  dataKeys?: DataKey<any>[]
}

export const ChartTooltip = <T,>({
  payload,
  active,
  tooltip,
  dataKeys,
}: ChartTooltipProps<T>) => {
  const data = payload?.[0]?.payload
  const colors = payload?.map(item => item.color).filter(Boolean)

  if (!active || !tooltip || !data) {
    return null
  }

  return (
    <LightBackgroundTooltip>{tooltip(data, dataKeys, colors)}</LightBackgroundTooltip>
  )
}
