import { ActionMenu, Badge, Flex, MoreBar, Token } from '@revolut/ui-kit'
import { CalendarDate, ChevronDownSmall } from '@revolut/icons'
import React, { useRef, useState } from 'react'
import { getPendingSchedulingStages } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import { RequiresFeatureFlagWrapper } from '@components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  stages: InterviewStageWithoutRoundInterface[]
  onClickSchedule: () => void
  onClickPrepCall: () => void
  onClickCalendarSchedule?: () => void
}

const ScheduleInterviewButton = ({
  stages,
  onClickSchedule,
  onClickCalendarSchedule,
  onClickPrepCall,
}: Props) => {
  const schedulingButtonRef = useRef(null)
  const [openSchedulingActionMenu, setOpenSchedulingActionMenu] = useState(false)

  const pendingSchedulingStages = getPendingSchedulingStages(stages)

  return (
    <>
      <MoreBar.Action
        useIcon={CalendarDate}
        // @ts-ignore TS lying, there is this prop
        useEndIcon={ChevronDownSmall}
        onClick={() => {
          setOpenSchedulingActionMenu(!openSchedulingActionMenu)
        }}
        ref={schedulingButtonRef}
      >
        Schedule interview
      </MoreBar.Action>
      <ActionMenu
        open={openSchedulingActionMenu}
        anchorRef={schedulingButtonRef}
        onClose={() => setOpenSchedulingActionMenu(false)}
      >
        <ActionMenu.Group>
          <ActionMenu.Item
            disabled={!pendingSchedulingStages.length}
            onClick={onClickSchedule}
          >
            <ActionMenu.Title>Interview stage</ActionMenu.Title>
            <ActionMenu.Description>
              Interview with interviewers filling scorecard
              {!pendingSchedulingStages.length && (
                <strong>
                  &nbsp;(There are no eligible stages to schedule the interview)
                </strong>
              )}
            </ActionMenu.Description>
          </ActionMenu.Item>

          {onClickCalendarSchedule && (
            <RequiresFeatureFlagWrapper flags={[FeatureFlags.CalendarScheduling]}>
              <ActionMenu.Item
                disabled={!pendingSchedulingStages.length}
                onClick={onClickCalendarSchedule}
              >
                <ActionMenu.Title>
                  <Flex>
                    Schedule interview using calendar&nbsp;&nbsp;
                    <Badge backgroundColor={Token.color.accent}>Beta</Badge>
                  </Flex>
                </ActionMenu.Title>
                <ActionMenu.Description>
                  Interview with interviewers filling scorecard
                  {!pendingSchedulingStages.length && (
                    <strong>
                      &nbsp;(There are no eligible stages to schedule the interview)
                    </strong>
                  )}
                </ActionMenu.Description>
              </ActionMenu.Item>
            </RequiresFeatureFlagWrapper>
          )}

          <ActionMenu.Item onClick={onClickPrepCall}>
            <ActionMenu.Title>Schedule a catch-up call</ActionMenu.Title>
            <ActionMenu.Description>
              General call with candidate with no scorecard to fill
            </ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

export default ScheduleInterviewButton
