import React, { useState } from 'react'
import { Box, Widget } from '@revolut/ui-kit'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { getLinkedinUrl } from '@src/utils/hiring'
import { InterviewFeedbackSideCV } from '@src/pages/Forms/InterviewFeedback/General/InterviewFeedbackSideCV'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { InterviewFeedbackSideInfo } from '@src/pages/Forms/InterviewFeedback/General/InterviewFeedbackSideInfo'
import SideBar from '@components/SideBar/SideBar'
import { PerformanceSummary } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSummary'
import { ScorecardSidebar } from '@src/pages/Forms/Candidate/Performance/Summary/ScorecardSidebar'
import { FeatureFlags } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import OnlineTestSummarySidebar from '@src/pages/Forms/Candidate/OnlineTestSummarySidebar/OnlineTestSummarySidebar'

type Props = {
  candidate: CandidateInterface
  interviewer?: InterviewToolInterviewer
  round: InterviewRoundInterface
  canViewCandidate: boolean
  open: boolean
  onClose: () => void
}

enum InterviewFeedbackSideTabs {
  CV = 'CV',
  CandidateProfile = 'Candidate profile',
  PreviousFeedback = 'Previous feedback',
}

export const InterviewFeedbackSide = ({
  candidate,
  round,
  interviewer,
  canViewCandidate,
  open,
  onClose,
}: Props) => {
  const [testStage, setTestStage] = useState<InterviewStageWithoutRoundInterface>()
  const [interviewFeedbackIds, setInterviewFeedbackIds] = useState<number[]>()
  const onCloseInterviewFeedbacks = () => {
    setInterviewFeedbackIds(undefined)
  }
  const featureFlags = useSelector(selectFeatureFlags)
  const canViewCandidatePerformanceProfile = featureFlags.includes(
    FeatureFlags.CandidatePerformanceProfile,
  )
  const tabs = [InterviewFeedbackSideTabs.CV, InterviewFeedbackSideTabs.CandidateProfile]

  if (canViewCandidatePerformanceProfile && canViewCandidate) {
    tabs.push(InterviewFeedbackSideTabs.PreviousFeedback)
  }

  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs,
    capitalize: true,
    defaultTab: InterviewFeedbackSideTabs.CV,
    onTabChange: tab => {
      if (tab !== InterviewFeedbackSideTabs.PreviousFeedback) {
        onCloseInterviewFeedbacks()
      }
    },
  })

  const linkedIn = getLinkedinUrl(candidate.links)

  return (
    <SideBar
      title="Candidate details"
      isOpen={open}
      onClose={onClose}
      sideProps={{ resizable: true }}
      useLayout
    >
      <Widget p="s-16">
        {tabBar}
        <Box mt="s-16">
          {currentTab === InterviewFeedbackSideTabs.CV && (
            <InterviewFeedbackSideCV
              linkedIn={linkedIn}
              candidateId={candidate.id}
              canViewCandidate={canViewCandidate}
            />
          )}
          {currentTab === InterviewFeedbackSideTabs.CandidateProfile && (
            <InterviewFeedbackSideInfo
              candidate={candidate}
              interviewer={interviewer}
              round={round}
            />
          )}
          {currentTab === InterviewFeedbackSideTabs.PreviousFeedback && (
            <PerformanceSummary
              round={round}
              onViewScorecard={feedbackIds => {
                setInterviewFeedbackIds(feedbackIds)
                setTestStage(undefined)
              }}
              onViewTest={stage => {
                setInterviewFeedbackIds(undefined)
                setTestStage(stage)
              }}
            />
          )}
        </Box>
        {!!interviewFeedbackIds?.length && (
          <ScorecardSidebar
            interviewFeedbackIds={interviewFeedbackIds}
            round={round}
            onClose={onCloseInterviewFeedbacks}
          />
        )}
        {!!testStage && (
          <OnlineTestSummarySidebar
            isOpen
            onClose={() => {
              setTestStage(undefined)
            }}
            stage={testStage}
            roundId={round?.id}
            candidateId={candidate?.id}
          />
        )}
      </Widget>
    </SideBar>
  )
}
