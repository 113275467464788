import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import CRMTable from '@src/pages/Recruitment/RecruitmentSubTabs/CRM/CRMTable'

export const CRMPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="CRM" backUrl={pathToUrl(ROUTES.RECRUITMENT.CANDIDATES)} />
      <PageBody maxWidth="100%">
        <CRMTable />
      </PageBody>
    </PageWrapper>
  )
}
