import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/CommonActions'
import { useReopenReviewCycle } from '@src/api/reviewCycles'
import { MoreBar } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props {
  cycle: ReviewCyclesInterface
}

export const ClosedCycleActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { mutateAsync: reopenCycle, isLoading } = useReopenReviewCycle(id)
  const showStatusPopup = useShowStatusPopup()

  const handleReopenCycle = async () => {
    await reopenCycle()
    showStatusPopup({ title: `${cycle.name} cycle is successfully re-opened` })
  }

  return (
    <MoreBar maxCount={2} aria-label="action panel">
      <CommonActions cycle={cycle} />
      <MoreBar.Action useIcon="Play" pending={isLoading} onClick={handleReopenCycle}>
        Re-open Cycle
      </MoreBar.Action>
    </MoreBar>
  )
}
