import React from 'react'
import { ActionButtonSkeleton, MoreBar, useToggle } from '@revolut/ui-kit'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import { useScorecardsValidation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScorecardsValidation'
import { ValidateScorecardsPopup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/Popup'
import { SwitchStageAction } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'
import { useScorecardsGeneration } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScorecardsGeneration'
import { GenerateScorecardsPopup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/GenerateScorecards/Popup'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  eligibleGroups: EligibleGroupInterface[]
  isStageSwitcherVisible: boolean
}

export const ReviewActions = ({
  cycle,
  stage,
  eligibleGroups,
  isStageSwitcherVisible,
}: Props) => {
  const { toPerformanceSummary } = useNavigation()
  const [isValidationPopupOpen, validationPopupToggler] = useToggle()
  const [isGenerationPopupOpen, generationPopupToggler] = useToggle()
  const { isValid, isLoading: isValidationDataLoading } = useScorecardsValidation(
    cycle.id,
  )
  const {
    isPending: isGenerationPending,
    isLoading: isGenerationDataLoading,
    generate,
  } = useScorecardsGeneration(cycle.id)

  if (isValidationDataLoading || isGenerationDataLoading) {
    return <ActionButtonSkeleton width={200} />
  }

  return (
    <MoreBar>
      {!isValid && (
        <MoreBar.Action useIcon="DocumentsPair" onClick={validationPopupToggler.on}>
          Validate scorecards
        </MoreBar.Action>
      )}
      {isValid && (
        <MoreBar.Action
          useIcon="DocumentsPair"
          pending={isGenerationPending}
          disabled={isGenerationPending}
          onClick={generationPopupToggler.on}
        >
          Generate scorecards
        </MoreBar.Action>
      )}
      <MoreBar.Action useIcon="16/ListBullet" onClick={toPerformanceSummary(cycle)}>
        Performance summary
      </MoreBar.Action>
      {isStageSwitcherVisible && isValid && (
        <SwitchStageAction cycle={cycle} stage={stage} />
      )}
      <ValidateScorecardsPopup
        cycle={cycle}
        eligibleGroups={eligibleGroups}
        isOpen={isValidationPopupOpen}
        isConfirmDisabled={isValid && isGenerationPending}
        isConfirmPending={isValid && isGenerationPending}
        handleOnValidConfirm={generate}
        handleClose={validationPopupToggler.off}
      />
      <GenerateScorecardsPopup
        cycle={cycle}
        eligibleGroups={eligibleGroups}
        isOpen={isGenerationPopupOpen}
        isConfirmDisabled={isGenerationPending}
        isConfirmPending={isGenerationPending}
        handleClose={generationPopupToggler.off}
        handleConfirm={generate}
      />
    </MoreBar>
  )
}
