import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import { useInfiniteGetTeamGoalsStats } from '@src/api/teams'
import { useInfiniteGetDepartmentGoalsStats } from '@src/api/department'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { OrgUnitGoalsStats } from '@src/interfaces/goals'

const goalsOwnerToFetcherMap: Record<
  GoalsOwner,
  typeof useInfiniteGetTeamGoalsStats | typeof useInfiniteGetDepartmentGoalsStats
> = {
  [GoalsOwner.Team]: useInfiniteGetTeamGoalsStats,
  [GoalsOwner.Department]: useInfiniteGetDepartmentGoalsStats,
}

interface GoalsStatsCount {
  totalCount: number
  withoutGoalsCount: number
  withGoalsCount: number
}

interface GoalsStats<T extends OrgUnitGoalsStats> {
  count: GoalsStatsCount
  withoutGoalsItems: T[]
  withGoalsItems: T[]
  isLoading: boolean
  isError: boolean
  isFetchingMoreWithoutGoals: boolean
  isFetchingMoreWithGoals: boolean
  handleFetchMoreWithoutGoals: VoidFunction
  handleFetchMoreWithGoals: VoidFunction
}

export const useGoalsStats = <T extends OrgUnitGoalsStats>(
  cycle: ReviewCyclesInterface,
  goalsOwner: GoalsOwner,
): GoalsStats<T> => {
  const useFetcher = goalsOwnerToFetcherMap[goalsOwner]

  const {
    data: withoutGoals,
    hasNextPage: hasMoreWithoutGoals,
    isFetchingNextPage: isFetchingMoreWithoutGoals,
    fetchNextPage: fetchMoreWithoutGoals,
    isLoading: isLoadingWithoutGoals,
    isError: isErrorWithoutGoals,
  } = useFetcher(cycle.id, false)

  const {
    data: withGoals,
    hasNextPage: hasMoreWithGoals,
    isFetchingNextPage: isFetchingMoreWithGoals,
    fetchNextPage: fetchMoreWithGoals,
    isLoading: isLoadingWithGoals,
    isError: isErrorWithGoals,
  } = useFetcher(cycle.id, true)

  const withoutGoalsCount = withoutGoals?.pages[0]?.count ?? 0
  const withGoalsCount = withGoals?.pages[0]?.count ?? 0
  const totalCount = withoutGoalsCount + withGoalsCount

  const withoutGoalsItems = (withoutGoals?.pages ?? []).reduce<T[]>(
    (allResults, page) => [...allResults, ...page.results] as T[],
    [],
  )

  const withGoalsItems = (withGoals?.pages ?? []).reduce<T[]>(
    (allResults, page) => [...allResults, ...page.results] as T[],
    [],
  )

  const handleFetchMoreWithoutGoals = () => {
    if (hasMoreWithoutGoals && !isFetchingMoreWithoutGoals) {
      fetchMoreWithoutGoals()
    }
  }

  const handleFetchMoreWithGoals = () => {
    if (hasMoreWithGoals && !isFetchingMoreWithGoals) {
      fetchMoreWithGoals()
    }
  }

  return {
    count: { totalCount, withoutGoalsCount, withGoalsCount },
    withoutGoalsItems,
    withGoalsItems,
    isLoading: isLoadingWithoutGoals || isLoadingWithGoals,
    isError: isErrorWithoutGoals || isErrorWithGoals,
    isFetchingMoreWithoutGoals,
    isFetchingMoreWithGoals,
    handleFetchMoreWithGoals,
    handleFetchMoreWithoutGoals,
  }
}
