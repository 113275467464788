import {
  triggerGenerateScorecards,
  useGetScorecardsGenerationsCategories,
  useGetScorecardsGenerationsStatus,
} from '@src/api/supportTool/eligibleEmployees'

interface ScorecardsGeneration {
  isLoading: boolean
  isPending: boolean
  generate: () => Promise<void>
}

export const useScorecardsGeneration = (
  cycleId: number | string,
): ScorecardsGeneration => {
  const { data: categories, isLoading: isCategoriesLoading } =
    useGetScorecardsGenerationsCategories()
  const enabledCategoriesIds = (categories ?? []).reduce<string[]>(
    (ids, { enabled, id }) => {
      if (enabled) {
        ids.push(id)
      }
      return ids
    },
    [],
  )
  const {
    data: generationStatus,
    isLoading: isGenerationStatusLoading,
    refetch: refetchGenerationStatus,
  } = useGetScorecardsGenerationsStatus()

  const generate = async () => {
    await triggerGenerateScorecards(cycleId, enabledCategoriesIds)
    await refetchGenerationStatus()
  }

  return {
    isLoading: isGenerationStatusLoading || isCategoriesLoading,
    isPending: isGenerationStatusLoading || !generationStatus,
    generate,
  }
}
