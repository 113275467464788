import React, { useMemo, useState } from 'react'
import {
  InterviewFeedbackInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { FilterButtonSkeleton, StatusWidget, VStack } from '@revolut/ui-kit'
import { StageTypeFilter } from '@src/pages/Forms/Candidate/Performance/Summary/StageTypeFilter'
import { OptionInterface } from '@src/interfaces/selectors'
import {
  OverallImpressionFeedback,
  OverallImpressionFeedbackSkeleton,
} from '@src/pages/Forms/Candidate/Performance/Summary/OverallImpressionFeedback'
import { removeStageTypes } from '@src/pages/Forms/Candidate/Performance/utils'

type OverallImpressionsProps = {
  round?: InterviewRoundInterface
  onViewScorecard: (feedbackIds: number[]) => void
}

export const OverallImpressionsSkeleton = () => {
  return (
    <VStack gap="s-16" px="s-16">
      <FilterButtonSkeleton />
      <OverallImpressionFeedbackSkeleton />
    </VStack>
  )
}

export const OverallImpressions = ({
  round,
  onViewScorecard,
}: OverallImpressionsProps) => {
  const { data, isLoading } = useFetchInterviewStages(round?.id)
  const [stageTypeFilter, setStageTypeFilter] = useState<OptionInterface[]>([])
  const currentStages = useMemo(() => {
    const stages = data.filter(({ interview_type }) => removeStageTypes(interview_type))
    if (!stageTypeFilter.length) {
      return stages
    }
    const stageTypes = stageTypeFilter.map(({ id }) => id)
    return stages.filter(({ interview_type }) => stageTypes.includes(interview_type))
  }, [data, stageTypeFilter])

  if (!round) {
    return null
  }
  if (isLoading) {
    return <OverallImpressionsSkeleton />
  }

  const feedbacks = currentStages.reduce<
    { feedback: InterviewFeedbackInterface; stage: InterviewStageWithoutRoundInterface }[]
  >((acc, stage) => {
    stage.interview_feedbacks
      .filter(item => item.status === 'completed')
      .forEach(feedback => {
        acc.push({
          stage,
          feedback,
        })
      })

    return acc
  }, [])

  return (
    <VStack gap="s-16" px="s-16">
      <StageTypeFilter
        value={stageTypeFilter}
        onChange={value => {
          setStageTypeFilter(value)
        }}
      />
      {feedbacks.map(item => (
        <OverallImpressionFeedback
          key={item.feedback.id}
          interviewFeedback={item.feedback}
          stage={item.stage}
          onViewScorecard={onViewScorecard}
        />
      ))}
      {!feedbacks.length && (
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D023.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D023@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D023@3x.png',
            }}
          />
          <StatusWidget.Title>Interview feedbacks will appear here</StatusWidget.Title>
        </StatusWidget>
      )}
    </VStack>
  )
}
