import React, { useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { InterviewFeedback } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedback'
import { Skeleton, TabBar, TabBarSkeleton, VStack } from '@revolut/ui-kit'
import { InterviewFeedbackInformation } from '../components/InterviewFeedbackInformation'

type ScorecardProps = {
  feedbackIds: number[]
  round?: InterviewRoundInterface
}

const Scorecard = ({ feedbackIds, round }: ScorecardProps) => {
  const { data, isLoading } = useFetchInterviewStages(round?.id)
  const [currentFeedbackId, setCurrentFeedbackId] = useState<string | null>(
    String(feedbackIds[0]),
  )
  if (isLoading) {
    return (
      <VStack gap="s-16">
        {feedbackIds?.length > 1 ? (
          <TabBarSkeleton>
            {feedbackIds.map(id => (
              <TabBarSkeleton.Item key={id} />
            ))}
          </TabBarSkeleton>
        ) : null}
        <Skeleton />
      </VStack>
    )
  }
  const feedbacks = data
    .flatMap(({ interview_feedbacks }) => interview_feedbacks)
    .filter(({ id }) => feedbackIds.includes(id))
    .sort((a, b) => feedbackIds.indexOf(a.id) - feedbackIds.indexOf(b.id))
  const feedback = feedbacks.find(({ id }) => String(id) === currentFeedbackId)
  return (
    <VStack gap="s-16">
      {feedbackIds?.length > 1 ? (
        <TabBar
          variant="navigation"
          value={currentFeedbackId}
          onChange={setCurrentFeedbackId}
        >
          {feedbacks.map(({ id, interviewer }) => (
            <TabBar.Item key={id} to={String(id)}>
              {interviewer?.full_name}
            </TabBar.Item>
          ))}
        </TabBar>
      ) : null}
      <InterviewFeedbackInformation feedback={feedback} />
      <InterviewFeedback
        hideRecommendation
        round={round}
        scorecard={feedback}
        stageType={feedback?.interview_type}
      />
    </VStack>
  )
}

type ScorecardSidebarProps = {
  interviewFeedbackIds: number[]
  round?: InterviewRoundInterface
  onClose: () => void
}

export const ScorecardSidebar = ({
  interviewFeedbackIds,
  round,
  onClose,
}: ScorecardSidebarProps) => {
  const key = interviewFeedbackIds?.join(',')
  return (
    <SideBar variant="wide" title="Assessment details" isOpen onClose={onClose}>
      <Scorecard key={key} feedbackIds={interviewFeedbackIds} round={round} />
    </SideBar>
  )
}
