import React, { CSSProperties } from 'react'
import { Details, DetailsSkeleton, Group } from '@revolut/ui-kit'
import { formatPrecisePercentage } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatPrecisePercentage'

const detailsStylesOverrides: CSSProperties = { paddingBottom: 0 }

export interface ReviewsDetail {
  name: string
  value: number
}

interface Props {
  details: ReviewsDetail[]
  isLoading: boolean
}

export const ReviewsDetails = ({ details, isLoading }: Props) => {
  if (isLoading) {
    return <ReviewsDetailsSkeleton />
  }

  return (
    <Group>
      {details.map(({ name, value }) => (
        <Details key={name} style={detailsStylesOverrides}>
          <Details.Title>{name}</Details.Title>
          <Details.Content>{formatPrecisePercentage(value)}</Details.Content>
        </Details>
      ))}
    </Group>
  )
}

const ReviewsDetailsSkeleton = () => (
  <Group>
    <DetailsSkeleton style={detailsStylesOverrides} />
    <DetailsSkeleton style={detailsStylesOverrides} />
    <DetailsSkeleton style={detailsStylesOverrides} />
  </Group>
)
