import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { useGetReviewStageStats } from '@src/api/reviewCycles'
import { ItemSkeleton } from '@revolut/ui-kit'
import { BaseStatsItem } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/StatsItems'
import React from 'react'
import { makePublishItemProps } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/constants'

interface PublishStatsProps {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage.ManagersPublish | ReviewCycleStage.EmployeesPublish
}

export const PublishStats = ({ cycle, stage }: PublishStatsProps) => {
  const { data, isLoading } = useGetReviewStageStats(cycle.id)

  const completedReviewsCount = data ? data.employees_with_reviews : 0
  const totalReviewsCount = data ? data.total_employees : 0

  const { title, description, iconName } = makePublishItemProps(
    stage,
    totalReviewsCount,
    completedReviewsCount,
  )

  if (isLoading) {
    return <ItemSkeleton />
  }

  return <BaseStatsItem title={title} description={description} iconName={iconName} />
}
