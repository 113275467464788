import React from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { PageActions } from '@components/Page/PageActions'
import { SkillsCardView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Cards/SkillsCardView'
import { CultureValuesCardView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Cards/CultureValuesCardView'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'
import { ProbationDeliverablesCardView } from '@src/pages/Forms/ProbationViewLayout/Cards/ProbationDeliverablesCardView'
import { getRecommendations } from '@src/pages/Forms/EmployeePerformanceViewLayout/ScorecardContentView'
import { BarRaiserView } from '@src/pages/Forms/EmployeePerformanceViewLayout/BarRaiserView'
import { FeedbackView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/FeedbackView'
import { ScorecardCardItemContextProvider } from '@src/pages/Forms/EmployeePerformanceLayout/components/ScorecardCardItemContextProvider'

export const ProbationViewContent = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()

  if (!values.summary) {
    return null
  }

  const recommendations = getRecommendations(values)

  return (
    <ScorecardCardItemContextProvider>
      <VStack space="s-16">
        <ProbationDeliverablesCardView />
        <SkillsCardView />
        <CultureValuesCardView />
        {!!recommendations.length && <BarRaiserView recommendations={recommendations} />}
        <FeedbackView
          recommendationData={recommendations?.[0]?.recommendation}
          reviews={recommendations?.map(el => el.recommendation)}
          gradesMap={values.grade_label_mapping}
        />
        <PageActions maxWidth="100%">
          <Flex justifyContent="center">
            <EditButton />
          </Flex>
        </PageActions>
      </VStack>
    </ScorecardCardItemContextProvider>
  )
})
