import { AxiosResponse } from 'axios'
import {
  PerformanceTimelineChartInterface,
  PerformanceCycleInterface,
  PerformanceReviewerSelector,
  PerformanceSelector,
  PerformanceTimelineEventsInterface,
  PerformanceTimelineInterface,
  PerformanceTimelineStats,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
  SkillsReviewsInterface,
  SingleTimelineEventInterface,
} from '../interfaces/performance'
import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import { ScorecardInterface } from '../interfaces/scorecard'
import { EmployeeGoal } from '../interfaces/employees'
import { GetRequestData, GetRequestInterface, Statuses } from '../interfaces'
import { filterSortPageIntoQuery } from '../utils/table'
import { useDelete, useFetch, usePost } from '@src/utils/reactQuery'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'

export const useGetGoals = (id: number | null, filters: FilterByInterface[]) => {
  const context = useFetch<{ results: EmployeeGoal[] }>(
    id ? `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/goals` : null,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
  )

  return { ...context, data: context.data?.results }
}

export const useDeleteGoal = (
  employeeId: number,
  filters: FilterByInterface[],
  updater: (
    oldData: GetRequestData<EmployeeGoal>,
    id?: string | number,
  ) => GetRequestData<EmployeeGoal>,
) =>
  useDelete(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/goals`,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
    updater,
  )

export const useAddGoal = (
  employeeId: number,
  filters: FilterByInterface[],
  updater: (
    oldData: GetRequestData<EmployeeGoal>,
    newData: EmployeeGoal,
  ) => GetRequestData<EmployeeGoal>,
) =>
  usePost(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/goals`,
    'v2',
    {
      params: filterSortPageIntoQuery(undefined, filters, undefined),
    },
    updater,
  )

export const getPerformanceReviews = (id: number, filters?: Object) => {
  return api.get<ScorecardInterface>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}${API.REVIEWS}/summary`,
    {
      params: filters || [],
    },
    'v2',
  )
}
export const postScorecard = (data: ScorecardInterface) =>
  api.post(
    `${API.EMPLOYEES}/${data.reviewed_person.id}${API.PERFORMANCE}${API.REVIEWS}`,
    data,
    {},
    'v2',
  )
export const getScorecardForEditing = (id: number) => {
  return api.get<ScorecardInterface>(`${API.SCORECARD}/${id}`, {}, 'v2')
}
export const putScorecardForEditing = (id: string, data: ScorecardInterface) => {
  return api.put<ScorecardInterface>(`${API.SCORECARD}/${id}`, data, {}, 'v2')
}
export const getReviewersSelector = (id: number, filters?: Object) => {
  return api.get<PerformanceReviewerSelector[]>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}${API.REVIEWS}`,
    {
      params: filters || [],
    },
    'v2',
  )
}

export const useGetPerformanceSelector = (id?: number | string) =>
  useFetch<PerformanceSelector[]>(
    id ? `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/reviewCycles` : null,
    'v2',
  )

export const postFeedbackRequest = (data: RequestFeedbackInterface) => {
  return api.post<RequestFeedbackInterface>(
    `${API.EMPLOYEES}/${data.reviewed_person.id}${API.PERFORMANCE}${API.REVIEWS}/requests`,
    data,
    {},
    'v2',
  )
}

export const postPerfReviewFeedbackRequest = (
  employeeId: number,
  id: string,
  data: {
    reviewer: { id: number }
    team_mate?: { id: number }
    reviewer_relation?: string
    category: ReviewCategory
    checkpoint_number?: number
  },
) => {
  return api.post<PerfReviewRequestFeedbackInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${id}/performanceReviews/requests`,
    data,
    {},
    'v2',
  )
}

export const deleteScorecard = (
  employeeId: number | string,
  cycleId: number | string,
  id: number,
) =>
  api.delete(
    `/employees/${employeeId}/performance/cycles/${cycleId}/performanceReviews/requests/${id}`,
    {},
    'v2',
  )

export const useGetFeedbackRequests = (id: number | null, filters?: Object) =>
  useFetch<RequestFeedbackInterface[]>(
    id ? `${API.EMPLOYEES}/${id}${API.PERFORMANCE}${API.REVIEWS}/requests` : null,
    'v2',
    { params: filters || [] },
  )

export const useGetPerfReviewFeedbackRequests = (
  employeeId: number | null,
  cycleId: string | null,
  filters?: Object,
) =>
  useFetch<{
    results: PerfReviewRequestFeedbackInterface[]
    requester_upwards_review_status: Statuses
  }>(
    employeeId && cycleId !== undefined
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/performanceReviews/requests`
      : null,
    'v2',
    { params: filters || [] },
  )

export const getScorecardStructure = (id: number, filter?: Object) =>
  api.get<ScorecardInterface>(
    `${API.EMPLOYEES}/${id}${API.PERFORMANCE}/scorecardsStructure`,
    {
      params: filter || [],
    },
    'v2',
  )

export const initiatePIP = (
  employeeId: number,
  data: Partial<PerformanceCycleInterface>,
) => {
  return api.post(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/PIPCycles`,
    data,
    {},
    'v2',
  )
}

export const cancelPIP = (employeeId: number, cycleId: string | number) => {
  return api.delete(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/PIPCycles/${cycleId}`,
    {},
    'v2',
  )
}

export const getExport = (employeeId: number, cycle__id: number | string, id?: string) =>
  api.get<BinaryType>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/reviews/exportSummary`,
    {
      responseType: 'arraybuffer',
      params: {
        cycle__id,
        ...(id ? { id } : {}),
      },
    },
    'v2',
  )

export const getPerformanceTimeline = (
  employeeId: number | string,
  cycleId?: number | string,
): Promise<AxiosResponse<GetRequestInterface<PerformanceTimelineInterface>>> =>
  apiWithoutHandling.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline`,
    {
      params: {
        cycle__id: cycleId,
      },
    },
    'v2',
  )

export const getPerformanceTimelineStats = (
  employeeId: number,
  cycleId?: number | string,
): Promise<AxiosResponse<PerformanceTimelineStats>> =>
  apiWithoutHandling.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/stats`,
    {
      params: {
        cycle__id: cycleId,
      },
    },
    'v2',
  )

export const getPerformanceTimelineChart = (employeeId: string | number) =>
  api.get<PerformanceTimelineChartInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/chart`,
    {},
    'v2',
  )

export const getPerformanceTimelineChartSkill = (
  employeeId: string | number,
  skill_id: string | number,
) =>
  api.get<PerformanceTimelineChartInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/skillChart`,
    {
      params: {
        skill_id,
      },
    },
    'v2',
  )

export const getPerformanceChartSkills = (employeeId: string | number) =>
  api.get<OptionInterface[]>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/chartSkills`,
    {},
    'v2',
  )

export const getPerformanceSkillsReviews = (
  employeeId: number,
): Promise<AxiosResponse<GetRequestInterface<SkillsReviewsInterface>>> =>
  apiWithoutHandling.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/skillsReviews`,
    {},
    'v2',
  )

export const getPerformanceTimelinEevents = (employeeId: number) => {
  return api.get<PerformanceTimelineEventsInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/events`,
    {},
    'v2',
  )
}

export const useGetPerformanceCycleTimelineEvents = (
  employeeId: number,
  cycleId?: number | string,
  isPerformance?: boolean,
) =>
  useFetch<SingleTimelineEventInterface[]>(
    employeeId && cycleId !== undefined && isPerformance
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceTimeline/cycleEvents`
      : null,
    'v2',
    { params: { cycle_id: cycleId } },
  )
