import React from 'react'
import pluralize from 'pluralize'
import { Action, Avatar, Box, IconName, Item, ItemSkeleton, Token } from '@revolut/ui-kit'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import StyledLink from '@components/Atoms/StyledLink'
import { transparentThemeBackgroundOverrides } from '@src/pages/Forms/ReviewCycle/ReviewCycle/styles'

export interface EligibleGroupItemContext {
  isValid?: boolean
  onItemActionClick?: VoidFunction
}

export const renderEligibilityGroupItem = (
  _index: number,
  { name, preview }: EligibleGroupInterface,
  { isValid, onItemActionClick }: EligibleGroupItemContext,
) => {
  const descriptionColor = isValid ? Token.color.success : undefined
  const iconName = isValid ? '16/Check' : 'Pencil'
  const description = `${preview?.count ?? 0} eligible ${pluralize(
    'employee',
    preview?.count ?? 0,
  )}`
  const isActionDisabled = typeof onItemActionClick !== 'function'

  return (
    <Item style={transparentThemeBackgroundOverrides}>
      <Item.Avatar>
        <Avatar useIcon="People" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{name}</Item.Title>
        <Item.Description color={descriptionColor}>{description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value pr="s-8">
          <Action
            useIcon={iconName}
            iconOnly
            disabled={isActionDisabled}
            color={descriptionColor}
            onClick={onItemActionClick}
          />
        </Item.Value>
      </Item.Side>
    </Item>
  )
}

export interface InvalidEligibilityItemProps {
  title: string
  description: string
  iconName: IconName
  actionLabel: string
  titleLink?: string
  handleActionClick: VoidFunction
}

export const renderInvalidEligibilityItem = (
  _index: number,
  {
    title,
    description,
    iconName,
    actionLabel,
    titleLink,
    handleActionClick,
  }: InvalidEligibilityItemProps,
) => (
  <Item useIcon={iconName}>
    <Item.Content>
      <Item.Title>
        {titleLink ? <StyledLink to={titleLink}>{title}</StyledLink> : title}
      </Item.Title>
      <Item.Description>{description}</Item.Description>
      <Box pt="s-8">
        <Action useIcon="LinkExternal" onClick={handleActionClick}>
          {actionLabel}
        </Action>
      </Box>
    </Item.Content>
  </Item>
)

export const InvalidEligibilityItemsSkeleton = () => (
  <>
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
  </>
)
