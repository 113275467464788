import React, { useRef, useState } from 'react'
import { Box, Button, Skeleton, Flex, H4 } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'

import Stat from '@src/components/Stat/Stat'
import FilterSelect, {
  FilterSelectType,
} from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { UseFetchResult } from '@src/interfaces'
import { TimeOffRequestYears } from '@src/api/timeOff'
import { getQueries } from '@src/utils/queryParamsHooks'

export const getDefaultYearOption = () => {
  const queryYear = getQueries()?.year
  const year = queryYear || new Date().getFullYear()
  return { id: year, name: `${year}` }
}

interface TimeOffYearSelectProps {
  api: UseFetchResult<{ options: TimeOffRequestYears[] }>
  onChange: (option: OptionInterface) => void
}

export const TimeOffYearSelect = ({ api, onChange }: TimeOffYearSelectProps) => {
  const [selectedYear, setSelectedYear] = useState<OptionInterface>(getDefaultYearOption)
  const [yearSelectOpen, setYearSelectOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)

  const yearOptionData = api.data?.options.map(option => ({
    ...option,
    name: `${option.name}`,
  }))

  const yearSelector = async () => ({ options: yearOptionData || [] })

  return (
    <Stat
      label="Year"
      val={
        <Box>
          <Button
            ref={selectRef}
            onClick={() => setYearSelectOpen(!yearSelectOpen)}
            variant="text"
            height="fit-content"
            p={0}
          >
            {api.isLoading ? (
              <Skeleton width={72} height="s-20" my="s-4" />
            ) : (
              <Flex alignItems="center" color="foreground">
                <H4 use="span" fontWeight="bold">
                  {selectedYear.name}
                </H4>
                {yearSelectOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
              </Flex>
            )}
          </Button>
          <FilterSelect
            open={yearSelectOpen}
            selector={yearSelector}
            anchorRef={selectRef}
            onClose={() => setYearSelectOpen(false)}
            value={[selectedYear]}
            onChange={options => {
              const option = options[0]
              if (option) {
                setSelectedYear(option)
                setYearSelectOpen(false)
                onChange(option)
              }
            }}
            type={FilterSelectType.SingleSelect}
            width={100}
          />
        </Box>
      }
    />
  )
}
