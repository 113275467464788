import React from 'react'
import { Cell, Flex } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import {
  locationColumn,
  employmentDataColumn,
  // TODO: temporarily hidden, uncomment when BE is ready - https://revolut.atlassian.net/browse/RHR-2703
  // payrollDataColumn,
  // trainingDataColumn,
  statusColumn,
  actionsColumn,
  personalDataColumn,
  benefitsDataColumn,
  timeAttendanceDataColumn,
} from '@src/constants/columns/dataRetentionSettingsGDPR'
import { TableNames } from '@src/constants/table'
import { getDataRetentionCountryList } from '@src/api/dataRetentionSettings'
import { DataRetentionCountriesInterface } from '@src/interfaces/dataRetentionSettings'

const ROW: RowInterface<DataRetentionCountriesInterface> = {
  linkToForm: data =>
    data.status === 'Draft'
      ? navigateTo(pathToUrl(ROUTES.FORMS.DATA_RETENTION.GENERAL, { countryId: data.id }))
      : navigateTo(
          pathToUrl(ROUTES.FORMS.DATA_RETENTION.PREVIEW, {
            countryId: data.id,
            id: data.gdpr_policy?.id,
          }),
        ),
  cells: [
    {
      ...locationColumn,
      width: 200,
    },
    {
      ...employmentDataColumn,
      width: 200,
    },
    {
      ...personalDataColumn,
      width: 200,
    },
    {
      ...benefitsDataColumn,
      width: 200,
    },
    {
      ...timeAttendanceDataColumn,
      width: 200,
    },
    // TODO: temporarily hidden, uncomment when BE is ready - https://revolut.atlassian.net/browse/RHR-2703
    // {
    //   ...trainingDataColumn,
    //   width: 200,
    // },
    // {
    //   ...payrollDataColumn,
    //   width: 200,
    // },
    {
      ...statusColumn,
      width: 100,
    },
    {
      ...actionsColumn,
      width: 140,
    },
  ],
}

const EmploymentPoliciesTable = () => {
  const table = useTable<DataRetentionCountriesInterface>({
    getItems: getDataRetentionCountryList,
  })

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.DataRetentionSettings}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Data retention settings will appear here"
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default EmploymentPoliciesTable
