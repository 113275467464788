import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  DeliverableOptions,
  deliverableOptionToPerformanceRating,
  PerformanceKPISection,
  PerformanceRating,
  ReviewCalculatedDeliverablesRatingsInterface,
  ReviewDataSectionInterface,
  SkillCardInterface,
  SkillSummary,
  SummaryCalculatedDeliverablesRatingsInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { IconName, Token } from '@revolut/ui-kit'
import { RecommendationQuestionInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { KpiRowInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import isObject from 'lodash/isObject'
import { notReachable } from '@src/utils/notReachable'
import { RecommendationExtraSectionQuestionInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummaryCollapsable'
import { isNull } from 'lodash'

export const getCellBackground = (
  rating: PerformanceRating | DeliverableOptions | string | null,
  isNewColours: boolean = false,
) => {
  if (isNewColours) {
    switch (rating) {
      case PerformanceRating.poor_plus:
      case PerformanceRating.poor:
      case PerformanceRating.basic_minus:
      case PerformanceRating.advanced_minus:
      case PerformanceRating.intermediate_minus:
      case PerformanceRating.improvement_needed:
        return Token.color.warning
      case PerformanceRating.basic:
      case PerformanceRating.intermediate:
      case PerformanceRating.dont_know:
      case PerformanceRating.skipped:
        return Token.color.foreground
      case PerformanceRating.basic_plus:
      case PerformanceRating.performing:
      case PerformanceRating.intermediate_plus:
      case PerformanceRating.superpower:
      case PerformanceRating.advanced:
      case PerformanceRating.advanced_plus:
      case PerformanceRating.expert_minus:
      case PerformanceRating.expert:
      case PerformanceRating.expert_plus:
        return Token.color.success
      default:
        return Token.color.foreground
    }
  }

  switch (rating) {
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
      return Token.color.teal_5
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
    case PerformanceRating.improvement_needed:
      return Token.color.orange_5
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
      return Token.color.teal_30
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
      return Token.color.teal_70
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
      return Token.color.teal
    default:
      return Token.color.background
  }
}

export const getComparedRatingSettings = (
  currentRating: PerformanceRating | undefined | null,
  expectedLevel?: DeliverableOptions | null,
): { icon: IconName; color: string; iconColor: string } => {
  const expectedPerformanceRating = isNull(expectedLevel)
    ? PerformanceRating.performing
    : deliverableOptionToPerformanceRating(expectedLevel)
  const gradeWeight = Object.keys(PerformanceRating)
  if (expectedPerformanceRating === currentRating) {
    return { icon: 'Check', iconColor: Token.color.grey50, color: Token.color.teal_30 }
  }

  if (
    currentRating &&
    gradeWeight.indexOf(expectedPerformanceRating) > gradeWeight.indexOf(currentRating)
  ) {
    return {
      icon: 'ArrowThinDown',
      iconColor: Token.color.warning,
      color: Token.color.grey50_30,
    }
  }

  return {
    icon: 'ArrowThinUp',
    iconColor: Token.color.success,
    color: Token.color.teal_50,
  }
}

export const getCellIconSettings = (
  rating: PerformanceRating,
): { icon: IconName; color: string } => {
  switch (rating) {
    case PerformanceRating.poor_plus:
    case PerformanceRating.poor:
    case PerformanceRating.improvement_needed:
    case PerformanceRating.basic:
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.advanced_minus:
    case PerformanceRating.basic_minus:
      return { icon: 'ArrowDown', color: Token.color.warning }
    case PerformanceRating.intermediate:
    case PerformanceRating.dont_know:
    case PerformanceRating.skipped:
      return { icon: 'Check', color: Token.color.greyTone50 }
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
    case PerformanceRating.expert_plus:
      return { icon: 'ArrowUp', color: Token.color.success }
    default:
      return notReachable(rating)
  }
}

export const performanceSummaryDeliverablesNameColumn: ColumnInterface<
  | ReviewDataSectionInterface
  | ReviewCalculatedDeliverablesRatingsInterface
  | SummaryCalculatedDeliverablesRatingsInterface
  | SkillCardInterface
  | SummarySkillCardInterface
  | SkillSummary
  | PerformanceKPISection
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: () => 'Contribution and impact',
}

export const performanceSummaryRatingColumn: ColumnInterface<
  | ReviewDataSectionInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | SkillSummary
  | SummarySkillCardInterface
  | PerformanceKPISection
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest rating',
  background: data => getCellBackground(data.rating || null),
}

export const performanceSummaryRatingColumnNewDesign: ColumnInterface<
  | ReviewDataSectionInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | SkillSummary
  | SummarySkillCardInterface
  | PerformanceKPISection
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest rating',
  background: data => getCellBackground(data.rating || null, true),
}

export const performanceSummaryCalculatedRatingColumn: ColumnInterface<
  | ReviewCalculatedDeliverablesRatingsInterface
  | SummaryCalculatedDeliverablesRatingsInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest rating',
  background: data => {
    const rating = data.recommended_rating || data.rating
    const ratingValue = isObject(rating) ? rating.rating : rating
    return getCellBackground(ratingValue)
  },
}

export const performanceSummaryExpectedCalcRatingColumn: ColumnInterface<
  | ReviewCalculatedDeliverablesRatingsInterface
  | SummaryCalculatedDeliverablesRatingsInterface
> = {
  type: CellTypes.insert,
  idPoint: 'rating_expectation',
  dataPoint: 'rating_expectation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Expected rating',
  insert: ({ data }) => {
    const expectation = isObject(data.rating_expectation)
      ? data.rating_expectation.rating
      : data.rating_expectation
    return PerformanceRatingTitle[expectation || 'none']
  },
  background: data => {
    const expectation = isObject(data.rating_expectation)
      ? data.rating_expectation.rating
      : data.rating_expectation
    return getCellBackground(expectation || null)
  },
}

export const performanceSummaryExpectedRatingColumn: ColumnInterface<
  | ReviewDataSectionInterface
  | KpiRowInterface
  | SkillCardInterface
  | SkillSummary
  | SummarySkillCardInterface
> = {
  type: CellTypes.insert,
  idPoint: 'rating_expectation',
  dataPoint: 'rating_expectation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Expected rating',
  insert: ({ data }) => PerformanceRatingTitle[data.rating_expectation || 'none'],
  background: data => getCellBackground(data.rating_expectation || null),
}

export const performanceSummarySkillsNameColumn: ColumnInterface<
  | SummarySkillCardInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | RecommendationQuestionInterface
  | RecommendationExtraSectionQuestionInterface
> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const performanceSummarySkillReviewerColumn: ColumnInterface<
  | SummarySkillCardInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | RecommendationQuestionInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reviewers',
}

export const performanceSummaryBarRaiserReviewerColumn: ColumnInterface<
  RecommendationQuestionInterface | RecommendationExtraSectionQuestionInterface
> = {
  type: CellTypes.insert,
  // just for resize purpose as data is agregated on front-end
  idPoint: 'LM',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'LM',
}
