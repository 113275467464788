import React from 'react'
import { opacity, Token } from '@revolut/ui-kit'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTableReturnType } from '@src/components/Table/hooks'
import {
  employeeShiftApprovalColumn,
  EmployeeShiftColumn,
  employeeShiftDateColumn,
  employeeShiftDurationColumn,
  employeeShiftEndColumn,
  employeeShiftStartColumn,
  employeeShiftTypesColumn,
  isChildShift,
} from '@src/constants/columns/attendance'
import { FilterByInterface, RowInterface, Stats } from '@src/interfaces/data'
import { EmployeeShiftApprovalInfoData } from '@src/interfaces/attendance'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'

export const ROW: RowInterface<EmployeeShiftColumn> = {
  isChildrenOpener: data => 'children_type' in data || !isChildShift(data),
  noChildrenRequest: data => 'employee' in data,
  highlight: data => {
    if ('children_type' in data) {
      return ''
    }
    if (isChildShift(data)) {
      return Token.color.greyTone2
    }
    return opacity(Token.colorChannel.deepGrey, 0.01)
  },
  cells: [
    {
      ...employeeShiftDateColumn,
      width: 260,
    },
    {
      ...employeeShiftTypesColumn,
      width: 380,
    },
    {
      ...employeeShiftStartColumn,
      width: 80,
    },
    {
      ...employeeShiftEndColumn,
      width: 80,
    },
    {
      ...employeeShiftDurationColumn,
      width: 80,
    },
    {
      ...employeeShiftApprovalColumn,
      width: 120,
    },
  ],
}

interface ScheduleTableInterface {
  table: useTableReturnType<EmployeeShiftColumn, Stats>
  row: RowInterface<EmployeeShiftColumn>
  teamId?: number
}

export const ScheduleTable = ({ table, row, teamId }: ScheduleTableInterface) => {
  return (
    <AdjustableTable
      name={TableNames.AttendanceSchedule}
      {...table}
      useWindowScroll
      row={row}
      expandableType="chevron"
      hideCount
      noReset
      fetchChildren={(parentIndexes, id) => {
        const tableRow = table.data[parentIndexes[0]]
        const date = 'children_type' in tableRow && tableRow.date
        return table.fetchChildren(
          parentIndexes,
          id,
          [
            {
              filters: [{ name: `${date}`, id: `${date}` }],
              columnName: 'date',
              nonResettable: true,
            },
            teamId
              ? {
                  filters: [{ name: `${teamId}`, id: `${teamId}` }],
                  columnName: 'id',
                  nonResettable: true,
                }
              : null,
          ].filter(Boolean) as FilterByInterface[],
        )
      }}
      onRowClick={(tableRow, parentIndexes) => {
        if ('children_type' in tableRow) {
          return
        }
        if (isChildShift(tableRow)) {
          const dateRow = table.data[parentIndexes[0]] as EmployeeShiftApprovalInfoData
          const employeeRow = dateRow.children[parentIndexes[1]]
          const employeeId = employeeRow?.employee.id
          if (employeeId && tableRow.type.id === 'time_off') {
            navigateTo(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                employeeId,
                id: tableRow.id,
              }),
            )
          }
          if (employeeId && tableRow.type.id === 'work') {
            navigateTo(
              pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
                employeeId,
                id: tableRow.id,
              }),
            )
          }
        }
      }}
    />
  )
}
