import React, { useEffect, useMemo, useRef } from 'react'
import { TableWidget } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RewardsSalary, RewardsSalaryStats } from '@src/interfaces/rewards'
import {
  rewardsFromDate,
  rewardsSalaryAmountColumn,
  rewardsToDate,
} from '@src/constants/columns/rewards'
import { getEmployeeRewardsSalary, getEmployeeRewardsSalaryStats } from '@src/api/rewards'
import { formatMoney } from '@src/utils/format'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<RewardsSalary> = {
  cells: [
    {
      ...rewardsFromDate,
      width: 100,
    },
    {
      ...rewardsToDate,
      width: 100,
    },
    {
      ...rewardsSalaryAmountColumn,
      width: 600,
    },
  ],
}

interface Props {
  data: EmployeeInterface
  currency?: string
  setCurrency: (currency: string) => void
}

export const Salary = ({ data, currency, setCurrency }: Props) => {
  const mounted = useRef(false)

  const table = useTable<RewardsSalary, RewardsSalaryStats>({
    getItems: getEmployeeRewardsSalary(data.id, currency),
    getStats: getEmployeeRewardsSalaryStats(data.id, currency),
  })

  useEffect(() => {
    if (mounted.current) {
      table.refresh()
    } else {
      mounted.current = true
    }
  }, [currency])

  const current = useMemo(() => {
    if (table.loading || !table.stats) {
      return undefined
    }

    if (!table.stats.result_currency) {
      return 'N/A'
    }

    return formatMoney(table.stats.current_salary, table.stats.result_currency)
  }, [table.stats, table.loading])

  return (
    <TableWidget>
      <TableWidget.Info>
        <CurrencySelect
          value={current}
          label="Current"
          onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
        />
      </TableWidget.Info>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.Salary}
          useWindowScroll
          dataType="Salary"
          row={ROW}
          {...table}
          noDataMessage="Salary will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
