import { API } from '@src/constants/api'
import { GetRequestData, GetRequestInterface } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { EmployeeEventInterface } from '@src/interfaces/employeeEvents'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { api } from '.'
import { addDays, format } from 'date-fns'

export const EMPLOYEE_EVENTS_END_DATE_FILTER_KEY = 'event_date'

export const EMPLOYEE_EVENTS_RELATION_TYPE_FILTER_KEY = 'relation_type'

export const EMPLOYEE_EVENTS_EVENT_TYPE_FILTER_KEY = 'event_type'

export const getEmployeeEventsDefaultFilter = (endDateFilter: number) => [
  {
    columnName: EMPLOYEE_EVENTS_END_DATE_FILTER_KEY,
    filters: [
      {
        id: `${format(new Date(), 'yyyy-MM-dd')},${format(
          addDays(new Date(), endDateFilter),
          'yyyy-MM-dd',
        )}`,
        name: '',
      },
    ],
    nonResettable: true,
  },
]

export const useEmployeeEvents = () => {
  return useFetch<GetRequestData<EmployeeEventInterface>>(
    API.EMPLOYEE_EVENTS,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, getEmployeeEventsDefaultFilter(7)),
    },
    true,
    {
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 90,
      refetchOnWindowFocus: false,
    },
  )
}

export const getEmployeeEvents = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<EmployeeEventInterface>> =>
  api.get(API.EMPLOYEE_EVENTS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
