import React from 'react'
import { StatusWidget, VStack } from '@revolut/ui-kit'
import {
  InterviewRoundSummaryRatingInterface,
  PerformanceHiringRoundSummaryInterface,
} from '@src/interfaces/interviewTool'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  performanceHiringName,
  performanceHiringInterviewers,
  performanceHiringResult,
} from '@src/constants/columns/performanceHiring'
import { TableNames } from '@src/constants/table'
import { PerformanceSection } from '@src/pages/Forms/Candidate/Performance/components/PerformanceSection'
import { getOnlineTestFromSummary } from '@src/pages/Forms/Candidate/Performance/utils'

const performanceSectionTableRow = {
  cells: [
    {
      ...performanceHiringName,
      width: 100,
    },
    {
      ...performanceHiringInterviewers,
      width: 50,
    },
    {
      ...performanceHiringResult,
      width: 50,
    },
  ],
}

type PerformanceSectionTableProps = {
  data?: InterviewRoundSummaryRatingInterface[]
  loading: boolean
  name:
    | TableNames.PerformanceHiringSummaryDeliverables
    | TableNames.PerformanceHiringSummarySkills
    | TableNames.PerformanceHiringSummaryValues
  onViewScorecard: (feedbackIds: number[]) => void
  onViewTest: (stageId: number) => void
}

const PerformanceSectionTable = ({
  data,
  loading,
  name,
  onViewScorecard,
  onViewTest,
}: PerformanceSectionTableProps) => {
  return (
    <AdjustableTable<InterviewRoundSummaryRatingInterface>
      data={data || []}
      loading={loading}
      name={name}
      count={data?.length || 0}
      hideCountAndButtonSection
      noDataMessage="No data"
      row={performanceSectionTableRow}
      onRowClick={row => {
        const feedbackIds =
          row.interviewer_ratings?.flatMap(
            ({ interview_feedback_ids }) => interview_feedback_ids,
          ) || []
        if (feedbackIds.length) {
          onViewScorecard(feedbackIds)
        }
        const onlineTest = getOnlineTestFromSummary(row)
        const stageId = onlineTest?.extra_fields?.[0]?.interview_stage_id
        if (stageId) {
          onViewTest(stageId)
        }
      }}
    />
  )
}

type PerformanceSectionsProps = {
  performanceRoundSummary?: PerformanceHiringRoundSummaryInterface
  loading: boolean
  onViewScorecard: (feedbackIds: number[]) => void
  onViewTest: (stageId: number) => void
}

export const PerformanceSections = ({
  performanceRoundSummary,
  loading,
  onViewScorecard,
  onViewTest,
}: PerformanceSectionsProps) => {
  const { delivery, skill, value } = performanceRoundSummary?.skill_ratings || {}
  return (
    <VStack gap="s-16" px="s-16">
      <PerformanceSection
        collapse={!loading && !delivery?.skills?.length}
        grade={delivery?.average_rating}
        loading={loading}
        title="Deliverables"
        type="delivery"
      >
        {delivery?.skills?.length ? (
          <PerformanceSectionTable
            data={delivery?.skills}
            loading={loading}
            name={TableNames.PerformanceHiringSummaryDeliverables}
            onViewScorecard={onViewScorecard}
            onViewTest={onViewTest}
          />
        ) : (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D374.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D374@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D374@3x.png',
              }}
            />
            <StatusWidget.Description>
              Deliverables assessment will appear here
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </PerformanceSection>
      <PerformanceSection
        collapse={!loading && !skill?.skills?.length}
        grade={skill?.average_rating}
        loading={loading}
        title="Skills"
        type="skill"
      >
        {skill?.skills?.length ? (
          <PerformanceSectionTable
            data={skill?.skills}
            loading={loading}
            name={TableNames.PerformanceHiringSummarySkills}
            onViewScorecard={onViewScorecard}
            onViewTest={onViewTest}
          />
        ) : (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D007.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D007@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D007@3x.png',
              }}
            />
            <StatusWidget.Description>
              Skills assessment will appear here
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </PerformanceSection>
      <PerformanceSection
        collapse={!loading && !value?.skills?.length}
        grade={value?.average_rating}
        loading={loading}
        title="Values"
        type="value"
      >
        {value?.skills?.length ? (
          <PerformanceSectionTable
            data={value?.skills}
            loading={loading}
            name={TableNames.PerformanceHiringSummaryValues}
            onViewScorecard={onViewScorecard}
            onViewTest={onViewTest}
          />
        ) : (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D090.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D090@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D090@3x.png',
              }}
            />
            <StatusWidget.Description>
              Value assessment will appear here
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </PerformanceSection>
    </VStack>
  )
}
