import React from 'react'

import { createChain, Ellipsis, HStack, Icon, Text, Token } from '@revolut/ui-kit'

import { ChangeType } from '.'

type Props = {
  from: React.ReactNode
  to: React.ReactNode
  type?: ChangeType
}
export const ValuesDiff = ({ from, to, type }: Props) => {
  const arrowChain = createChain(<Icon name="ArrowThinRight" size={12} />)
  const maxPreviewWidth = 96

  return (
    <HStack align="center" space="s-4">
      {type === 'create' && <Icon name="PlusCircle" size={13} color={Token.color.blue} />}
      {type === 'increase' && (
        <Icon name="ArrowUp" size={15} color={Token.color.success} />
      )}
      {type === 'decrease' && (
        <Icon name="ArrowDown" size={15} color={Token.color.warning} />
      )}
      {type === 'unknown' && (
        <Icon mr="s-4" name="ArrowExchange" size={15} color={Token.color.greyTone50} />
      )}
      {arrowChain(
        from ? (
          <Ellipsis maxWidth={maxPreviewWidth}>
            <Text color={Token.color.greyTone50} textDecoration="line-through">
              {from}
            </Text>
          </Ellipsis>
        ) : undefined,
        <Ellipsis maxWidth={from ? maxPreviewWidth : maxPreviewWidth * 2}>
          <Text>{to}</Text>
        </Ellipsis>,
      )}
    </HStack>
  )
}
