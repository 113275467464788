import React from 'react'
import { Link, Text, Token } from '@revolut/ui-kit'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { StatusTag } from '@src/components/Page/Header/PageHeaderTitle'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { Statuses } from '@src/interfaces'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'

type RequisitionFormTitleProps = {
  actions: React.ReactNode
  data: RequisitionInterface
}

const getApprovalStatusIconName = (status?: Statuses | ApprovalStatuses) => {
  switch (status) {
    case Statuses.pending:
      return '16/SandWatch'
    case Statuses.opened:
      return '16/Live'
    default:
      return undefined
  }
}

export const RequisitionFormTitle = ({ actions, data }: RequisitionFormTitleProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const currentStatus = data.status
  return (
    <PageHeader.Title
      actions={actions}
      useIcon="AddContact"
      avatar={
        <EntityAvatar
          data={{
            id: +values.specialisation.id,
            icon: values.specialisation.icon,
          }}
          defaultIcon={FUNCTION_DEFAULT_ICON}
          canEdit={false}
        />
      }
      labels={
        <PageHeader.LabelsBar>
          <StatusTag
            useIcon={getApprovalStatusIconName(currentStatus)}
            status={currentStatus}
          />
          {data.is_confidential && (
            <PageHeader.Label useTag color={Token.color.orange}>
              Confidential
            </PageHeader.Label>
          )}
          <PageHeader.Label useTag> #{values.id}</PageHeader.Label>
          <PageHeader.Label
            icon="People"
            to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
              id: data.team.id,
            })}
          >
            {data.team.name}
          </PageHeader.Label>
        </PageHeader.LabelsBar>
      }
      title={data.requisition_title}
    />
  )
}

type RequisitionFormSubtitleProps = {
  data: RequisitionInterface
}

export const RequisitionFormSubtitle = ({ data }: RequisitionFormSubtitleProps) => {
  return (
    <>
      {data.specialisation?.id && (
        <Link
          href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
            id: data.specialisation.id,
          })}
          target="_blank"
          color="inherit"
        >
          {data.specialisation.name}
        </Link>
      )}
      {!!data.specialisation?.id && !!data.team?.name && (
        <Text color="grey-20"> at </Text>
      )}
      {data.team?.name && (
        <Link
          to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.team.id })}
          use={InternalLink}
          target="_blank"
          color="inherit"
        >
          {data.team.name}
        </Link>
      )}
    </>
  )
}
