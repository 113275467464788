import React, { RefObject, useContext, useMemo, useState } from 'react'

export const CardItemContext = React.createContext<{
  currentItem: string | null
  setCurrentItem: React.Dispatch<React.SetStateAction<string | null>>
  currentItemRef: RefObject<HTMLDivElement> | null
  setCurrentItemRef: React.Dispatch<
    React.SetStateAction<React.RefObject<HTMLDivElement> | null>
  >
} | null>(null)

export const useCardItemContext = () => {
  return useContext(CardItemContext)
}

export const ScorecardCardItemContextProvider: React.FC = ({ children }) => {
  const [currentItem, setCurrentItem] = useState<string | null>(null)
  const [currentItemRef, setCurrentItemRef] = useState<RefObject<HTMLDivElement> | null>(
    null,
  )

  const contextValue = useMemo(
    () => ({ currentItem, setCurrentItem, currentItemRef, setCurrentItemRef }),
    [currentItem, setCurrentItem, currentItemRef, setCurrentItemRef],
  )

  return (
    <CardItemContext.Provider value={contextValue}>{children}</CardItemContext.Provider>
  )
}
