import React, { useState } from 'react'
import HTMLEditor, { DEFAULT_MODULES } from '@components/HTMLEditor/HTMLEditor'
import {
  Box,
  Ellipsis,
  Flex,
  HStack,
  IconButton,
  Text,
  TextButton,
  Token,
} from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import { css } from 'styled-components'
import { MetricsGridHandle } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsGrid'

interface TextItemProps {
  id: string
  html?: string
  updateContent: (id: string, content: string) => void
  parentRef: React.RefObject<MetricsGridHandle>
}

const sectionCss = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  p {
    margin: 16px 0;
  }

  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`

export const TextItem = ({ id, html, updateContent, parentRef }: TextItemProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <Box overflow="auto" height="100%">
      <Flex alignItems="center" pb="s-8">
        <Ellipsis style={{ flexGrow: 1 }}>
          <Text variant="tile" use="div" textAlign="center">
            Text card
          </Text>
        </Ellipsis>
        <HStack align="center" space="s-16" width={65}>
          {isEditing ? (
            <TextButton onClick={() => setIsEditing(false)}>Done</TextButton>
          ) : (
            <IconButton
              onClick={() => setIsEditing(true)}
              useIcon="Pencil"
              aria-label="Edit text card"
              color={Token.color.accent}
              size={16}
              tapArea={32}
            />
          )}
          <IconButton
            onClick={() => {
              parentRef.current?.remove(id)
            }}
            useIcon="Delete"
            aria-label="Delete text card"
            color={Token.color.accent}
            size={16}
            tapArea={32}
          />
        </HStack>
      </Flex>
      <Box height="calc(100% - 32px)">
        {isEditing ? (
          <HTMLEditor
            value={html}
            placeholder="Dashboard description"
            onChange={v => {
              if (v !== html) {
                updateContent(id, v || '')
              }
            }}
            nonResizable
            fontSize="14px"
            modules={DEFAULT_MODULES}
            greyBg
            height={180}
          />
        ) : (
          <Text
            css={sectionCss}
            use="pre"
            textAlign="justify"
            whiteSpace="pre-wrap"
            variant="caption"
            color={Token.color.foreground}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(html || ''),
            }}
          />
        )}
      </Box>
    </Box>
  )
}
