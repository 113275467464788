import { OrgUnitGoalsStats } from '@src/interfaces/goals'
import { Action, Avatar, Box, Item, ItemSkeleton, Status } from '@revolut/ui-kit'
import React from 'react'
import pluralize from 'pluralize'
import { Context } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/List'

interface BaseItemProps extends OrgUnitGoalsStats {
  side?: React.ReactNode
}

const BaseItem = ({ id, name, owner, side: Side }: BaseItemProps) => (
  <Box key={id} pb="s-16">
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Bank" />
      </Item.Avatar>
      <Item.Content>
        {name && <Item.Title>{name}</Item.Title>}
        {owner && (
          <Item.Description>
            <Status useIcon="Profile">{owner.name}</Status>
          </Item.Description>
        )}
      </Item.Content>
      {Side && <Item.Side>{Side}</Item.Side>}
    </Item>
  </Box>
)

export const renderWithoutGoalsItem = <T extends OrgUnitGoalsStats>(
  _index: number,
  props: T,
) => <BaseItem {...props} />

export const renderWithGoalsItem = <T extends OrgUnitGoalsStats, C extends Context>(
  _index: number,
  props: T,
  context: C,
) => (
  <BaseItem
    {...props}
    side={
      <Action onClick={() => context?.onClick(props.id)}>
        {pluralize('goal', props.goals_count, true)}
      </Action>
    }
  />
)

export const ListItemsSkeleton = () => (
  <>
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
  </>
)
