import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { Priority, RequisitionInterface } from '@src/interfaces/requisitions'
import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getRequisitionsSelectorOptions } from '@src/api/requisitions'
import Table from '@components/TableV2/Table'
import { Statuses } from '@src/interfaces'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'

const getStatusVariant = (status: Statuses) => {
  switch (status) {
    case Statuses.pending:
      return 'warning'

    case Statuses.rejected:
      return 'danger'

    default:
      return 'neutral'
  }
}

export const requisitionStatusColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status_display',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.requisitions_status,
  insert: ({ data }) => (
    <Table.StatusCell status={data.status} variant={getStatusVariant(data.status)} />
  ),
  title: 'Status',
}

export const requisitionIdColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: 'id',
  filterKey: 'id',
  filterType: FilterType.number,
  selectorsKey: selectorKeys.none,
  title: 'ID',
}

export const createRequisitionTitleColumn = (
  isConfidential: boolean,
): ColumnInterface<RequisitionInterface> => ({
  type: CellTypes.text,
  idPoint: 'requisition_title',
  dataPoint: 'requisition_title',
  sortKey: 'requisition_title',
  filterKey: 'id',
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: data.id }),
  selectorsKey: () => getRequisitionsSelectorOptions(isConfidential),
  title: 'Title',
  isBig: true,
})

export const createRequisitionTitleIconColumn = (
  isConfidential: boolean,
): ColumnInterface<RequisitionInterface> => ({
  type: CellTypes.insert,
  idPoint: 'requisition_title',
  dataPoint: 'requisition_title',
  sortKey: 'requisition_title',
  filterKey: 'id',
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: data.id }),
  selectorsKey: () => getRequisitionsSelectorOptions(isConfidential),
  title: 'Title',
  isBig: true,
  insert: ({ data, url }) => (
    <Table.EntityCell
      useIcon={data.specialisation?.icon || FUNCTION_DEFAULT_ICON}
      avatar={data.avatar}
      url={url}
    >
      {data.requisition_title}
    </Table.EntityCell>
  ),
})

export const requisitionPriorityColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'priority',
  dataPoint: 'priority',
  sortKey: 'priority',
  filterKey: 'priority',
  selectorsKey: selectorKeys.requisitions_priority,
  colors: (data: RequisitionInterface) => {
    if (data.priority === Priority.High || data.priority === Priority.Company) {
      return Token.color.red
    }

    return Token.color.foreground
  },
  title: 'Priority',
  insert: ({ data }) => (
    <Text fontWeight={data.priority === Priority.Company ? 500 : 300}>
      {capitalize(data.priority)}
    </Text>
  ),
}

export const requisitionHeadcountColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'remaining_vs_total_headcount',
  sortKey: 'headcount',
  filterType: FilterType.range,
  filterKey: 'remaining_headcount',
  selectorsKey: selectorKeys.none,
  colors: () => Token.color.greyTone50,
  textAlign: 'right',
  title: 'Remaining headcount',
}

export const requisitionBackfillColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'backfill',
  dataPoint: 'backfill',
  sortKey: 'backfill',
  filterKey: 'backfill',
  filterType: FilterType.boolean,
  selectorsKey: () =>
    Promise.resolve({
      options: [
        {
          id: 'False',
          name: 'New hire',
        },
        {
          id: 'True',
          name: 'Backfill (Replacement)',
        },
      ],
    }),
  insert: ({ data }) => (data.backfill ? 'Backfill (Replacement)' : 'New hire'),
  title: 'Type',
}

export const requisitionQueuePosition: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'pipeline_queue_position',
  dataPoint: 'pipeline_queue_position',
  sortKey: 'pipeline_queue_position',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Q position',
  insert: ({ data }) =>
    data.pipeline_queue_position
      ? `${data.pipeline_queue_position}/${data.pipeline_queue_total}`
      : '-',
}
