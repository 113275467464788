import React from 'react'
import { HStack, Icon, Token } from '@revolut/ui-kit'

import { getNonEmptyChanges } from '@src/apps/People/PayrollV2/PayCycle/common'
import { ValuesDiff } from '@src/apps/People/PayrollV2/PayCycle/common/ValuesDiff'
import Tooltip from '@src/components/Tooltip/Tooltip'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { PayrollTimelineEventInterface } from '@src/interfaces/payrollV2'
import { formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import { selectorKeys } from '../api'

export const payCycleTimelineEmployeeNameColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.employee.id',
    dataPoint: 'content.employee.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee name',
    insert: ({ data }) => <UserWithAvatar {...data.content.employee} />,
  }

export const payCycleTimelineEffectiveDateTimeColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'effective_date_time',
    dataPoint: 'content.employee.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Effective date time',
    insert: ({ data }) => formatWithoutTimezone(data.effective_date_time, true),
  }

export const payCycleTimelineCategoryColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.domain_category',
    dataPoint: 'content.domain_category',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Category',
    insert: ({ data }) => formatSnakeCase(data.content.domain_category),
  }

export const payCycleTimelineFieldColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.domain_name',
    dataPoint: 'content.domain_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Field',
    insert: ({ data }) => {
      const changeProps = getNonEmptyChanges(data)
      return changeProps.length ? changeProps[0].label : '-'
    },
  }

export const payCycleTimelineChangesColumn: ColumnInterface<PayrollTimelineEventInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'content.id',
    dataPoint: 'content.id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Changes',
    notHoverable: true,
    insert: ({ data }) => {
      const changesProps = getNonEmptyChanges(data)

      return changesProps.length ? (
        <HStack align="center" space="s-4">
          <ValuesDiff
            from={changesProps[0].from}
            to={changesProps[0].to}
            type={changesProps[0].changeType}
          />
          {changesProps.length > 1 && (
            <Tooltip placement="left" text="Click to see all changes">
              <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
            </Tooltip>
          )}
        </HStack>
      ) : (
        '-'
      )
    },
  }
