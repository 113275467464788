import React from 'react'
import { InternalDashboardItemInterface } from '@src/interfaces/analyticsDashboards'
import { MetricsItem } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsItem'
import {
  Grid,
  AspectRatio,
  Token,
  useMatchMedia,
  Flex,
  Text,
  Placeholder,
} from '@revolut/ui-kit'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import DOMPurify from 'dompurify'
import { css } from 'styled-components'

interface MetricsGridProps {
  dashboardId: number
  layout: InternalDashboardItemInterface[]
}

const sectionCss = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  p {
    margin: 16px 0;
  }

  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`

const getGridArea = (item: InternalDashboardItemInterface) => {
  const rowStart = item.row + 1
  const colStart = item.column + 1
  const rowEnd = rowStart + item.height
  const colEnd = colStart + item.width

  return `${rowStart} / ${colStart} / ${rowEnd} / ${colEnd}`
}

export const MetricsGrid = ({ dashboardId, layout }: MetricsGridProps) => {
  const isMediumScreen = useMatchMedia(Token.media.md)

  return (
    <>
      <Grid columns={{ all: 1, md: 12 }} gap="s-16">
        {layout.map(item => (
          <AspectRatio
            ratio={isMediumScreen ? item.width / item.height : 1}
            key={item.query?.id}
            style={
              isMediumScreen
                ? {
                    gridArea: getGridArea(item),
                  }
                : undefined
            }
            bg={Token.color.widgetBackground}
            radius="widget"
            width="100%"
            height="100%"
            p="s-16"
          >
            {item.query && (
              <ErrorBoundary
                fallback={
                  <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Placeholder>
                      <Placeholder.Image src="https://assets.revolut.com/assets/3d-images-v2/3D018.png" />
                      <Placeholder.Title>Failed to load</Placeholder.Title>
                    </Placeholder>
                  </Flex>
                }
              >
                <MetricsItem dashboardId={dashboardId} queryId={item.query.id} />
              </ErrorBoundary>
            )}
            {item.content && (
              <Text
                css={sectionCss}
                use="pre"
                textAlign="justify"
                whiteSpace="pre-wrap"
                variant="caption"
                color={Token.color.foreground}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.content.html),
                }}
              />
            )}
          </AspectRatio>
        ))}
      </Grid>
    </>
  )
}
