import React from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { DeliverablesCardView } from './Cards/DeliverablesCardView'
import { SkillsCardView } from './Cards/SkillsCardView'
import { CultureValuesCardView } from './Cards/CultureValuesCardView'
import { BarRaiserView } from './BarRaiserView'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { PageActions } from '@components/Page/PageActions'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'
import { FeedbackView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/FeedbackView'
import { ScorecardCardItemContextProvider } from '@src/pages/Forms/EmployeePerformanceLayout/components/ScorecardCardItemContextProvider'

export const getRecommendations = (values: ReviewSummaryInterface) => {
  const lmRecommendation = values.summary?.line_manager_extra_section
  const fmRecommendation = values.summary?.functional_manager_extra_section
  const peerRecommendations = values.summary?.peer_extra_sections
  const probationPipRecommendation = values.summary?.recommendation_extra_sections
  const obsoletePipRecommendation = values.summary?.pip_extra_sections

  const recommendations = []

  if (lmRecommendation) {
    recommendations.push({
      recommendation: lmRecommendation,
      type: RecommendationTypes.LM,
    })
  }
  if (fmRecommendation) {
    recommendations.push({
      recommendation: fmRecommendation,
      type: RecommendationTypes.FM,
    })
  }
  if (peerRecommendations?.length) {
    peerRecommendations.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PEER,
      })
    })
  }
  if (probationPipRecommendation?.length) {
    probationPipRecommendation.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PIP,
      })
    })
  }
  if (obsoletePipRecommendation?.length) {
    obsoletePipRecommendation.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PIP_V1,
      })
    })
  }

  return recommendations
}

export const ScorecardContentView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()

  if (!values.summary) {
    return null
  }

  const recommendations = getRecommendations(values)

  return (
    <ScorecardCardItemContextProvider>
      <VStack space="s-16">
        <DeliverablesCardView />
        <SkillsCardView />
        <CultureValuesCardView />
        {!!recommendations.length && <BarRaiserView recommendations={recommendations} />}
        <AdditionalQuestions data={values.summary} isViewMode />
        <FeedbackView
          recommendationData={recommendations?.[0]?.recommendation}
          reviews={recommendations?.map(el => el.recommendation)}
          gradesMap={values.grade_label_mapping}
        />
        <PageActions maxWidth="100%">
          <Flex justifyContent="center">
            <EditButton />
          </Flex>
        </PageActions>
      </VStack>
    </ScorecardCardItemContextProvider>
  )
})
